import React, { useState, useEffect, useRef } from 'react'
import CustomSnackbar from 'components/CustomSnackbar'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

import { mainBoxStyle } from 'styles.js'

import url from 'config/server-url'

const InviteStudent = () => {
  const snackbarRef = useRef()
  const [newStudent, setNewStudent] = useState({
    courseId: '',
    role: 'student',
    email: '',
    password: '',
    studentName: '',
    studentContact: '',
    studentAddress: '',
    studentStatus: 'active'
  })
  const [courseList, setCourseList] = useState([])

  const handleInputChange = e => {
    const { name, value } = e.target
    setNewStudent(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleCreateStudent = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(url + 'api/students/inviteStudent', {
        ...newStudent,
        adminId
      })

      if (response.status === 201) {
        setNewStudent({
          courseId: '',
          role: 'student',
          email: '',
          password: '',
          studentName: '',
          studentContact: '',
          studentAddress: '',
          studentStatus: 'active'
        })
        snackbarRef.current.displaySnackBar('Student Created', 'success')
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message === 'User with this email already exists'
      ) {
        snackbarRef.current.displaySnackBar(
          error.response.data.message,
          'error'
        )
      }
    }
  }

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  useEffect(() => {
    getAdminCourses()
  }, [])

  return (
    <Box sx={mainBoxStyle}>
      <h2>Invite Student</h2>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            size='small'
            type='text'
            name='studentName'
            label='Enter Student Name'
            variant='outlined'
            value={newStudent.studentName}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size='small'
            type='text'
            name='email'
            value={newStudent.email}
            onChange={handleInputChange}
            label='Enter Student Email'
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size='small'
            type='password'
            name='password'
            value={newStudent.password}
            onChange={handleInputChange}
            label='Enter Student Password'
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl size='small' fullWidth>
            <InputLabel id='courseName'>Select Course</InputLabel>
            <Select
              labelId='courseName'
              value={newStudent.courseId}
              label='Select Course'
              onChange={handleInputChange}
              name='courseId'
            >
              {courseList.map(course => (
                <MenuItem key={course._id} value={course._id}>
                  {course.courseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <TextField
            size='small'
            type='text'
            name='studentContact'
            value={newStudent.studentContact}
            onChange={handleInputChange}
            label='Enter Student Contact'
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size='small'
            type='text'
            name='studentAddress'
            value={newStudent.studentAddress}
            onChange={handleInputChange}
            label='Enter Student Address'
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item container justifyContent='flex-end' xs={12}>
          <Button
            variant='contained'
            onClick={handleCreateStudent}
            startIcon={<PersonAddIcon />}
          >
            Invite Student
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default InviteStudent
