import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import url from 'config/server-url'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import ReactPlayer from 'react-player'

const thumbnails = [
  'https://via.placeholder.com/150?text=Video+1',
  'https://via.placeholder.com/150?text=Video+2',
  'https://via.placeholder.com/150?text=Video+3'
]

const VideoPlayerWithPlaylist = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [signedUrls, setSignedUrls] = useState({})
  const [watchingTime, setWatchingTime] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progressReported, setProgressReported] = useState(false)
  const playerRef = useRef(null)
  const [role, setRole] = useState(null)

  useEffect(() => {
    setRole(localStorage.getItem('role'))
  }, [])

  const getSignedUrl = async fileName => {
    try {
      const response = await axios.post(`${url}api/s3/get`, {
        fileName
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (!signedUrls[videos[currentVideoIndex].name]) {
        const signedUrl = await getSignedUrl(videos[currentVideoIndex].name)
        setSignedUrls(prev => ({
          ...prev,
          [videos[currentVideoIndex].url]: signedUrl
        }))
      }
    }
    fetchSignedUrl()
  }, [currentVideoIndex, videos])

  useEffect(() => {
    let interval
    if (isPlaying) {
      interval = setInterval(() => {
        setWatchingTime(prev => prev + 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isPlaying])

  useEffect(() => {
    setProgressReported(false)
    setWatchingTime(0)
    setIsPlaying(false)
  }, [currentVideoIndex])

  const handleVideoSelection = index => {
    setCurrentVideoIndex(index)
  }

  const handleProgress = ({ playedSeconds }) => {
    const currentVideo = videos[currentVideoIndex]
    const halfDuration = playerRef.current.getDuration() / 2

    if (watchingTime >= halfDuration && !progressReported) {
      updateProgress(true)
      setProgressReported(true)
    }
  }

  const updateProgress = async () => {
    if (role !== 'student') return

    try {
      const currentVideo = videos[currentVideoIndex]
      await axios.post(`${url}api/newCourse/updateProgress`, {
        studentId: localStorage.getItem('_id'),
        courseId: currentVideo.courseId,
        chapterId: currentVideo.chapterId,
        categoryId: currentVideo.categoryId,
        resourceId: currentVideo.resourceId,
      })
    } catch (error) {
      console.error('Error updating progress:', error)
    }
  }

  const handlePlay = () => setIsPlaying(true)
  const handlePause = () => setIsPlaying(false)

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {videos[currentVideoIndex].name}
        </Typography>
        <ReactPlayer
          ref={playerRef}
          url={signedUrls[videos[currentVideoIndex].url] || ''}
          controls
          width='100%'
          height='auto'
          onProgress={handleProgress}
          onPlay={handlePlay}
          onPause={handlePause}
        />
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {videos.map((res, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleVideoSelection(index)}
              selected={index === currentVideoIndex}
            >
              <ListItemAvatar>
                <Avatar
                  src={thumbnails[index]}
                  alt={`Thumbnail for video ${index + 1}`}
                />
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default VideoPlayerWithPlaylist
