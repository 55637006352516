import React, { useState, useEffect } from 'react'

import axios from 'axios'
import url from 'config/server-url'
import Carousel from 'react-material-ui-carousel'

import { Box, Button } from '@mui/material'

const ImageCarousel = ({ imageUrls }) => {
  const [signedUrls, setSignedUrls] = useState([])
  const [viewedImages, setViewedImages] = useState(new Set())
  const [currentIndex, setCurrentIndex] = useState(0)
  const [role, setRole] = useState(null)

  const getSignedUrl = async fileName => {
    try {
      const response = await axios.post(`${url}api/s3/get`, {
        fileName
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    setRole(localStorage.getItem('role'))
  }, [])

  useEffect(() => {
    const fetchSignedUrls = async () => {
      const newSignedUrls = await Promise.all(
        imageUrls.map(async (image) => {
          return await getSignedUrl(image.name)
        })
      )
      setSignedUrls(newSignedUrls)
    }
    fetchSignedUrls()
  }, [imageUrls])

  const handleImageView = async (index) => {
    if (!viewedImages.has(index)) {
      setViewedImages(prev => new Set(prev).add(index))
      if (role === 'student') {
        try {
          await axios.post(`${url}api/newCourse/updateProgress`, {
            studentId: localStorage.getItem('_id'),
            courseId: imageUrls[index].courseId,
            chapterId: imageUrls[index].chapterId,
            categoryId: imageUrls[index].categoryId,
            resourceId: imageUrls[index].resourceId,
          })
        } catch (error) {
          console.error('Error updating progress:', error)
        }
      }
    }
  }

  const handleChange = (index) => {
    setCurrentIndex(index)
    handleImageView(index)
  }

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imageUrls.length
    setCurrentIndex(nextIndex)
    handleImageView(nextIndex)
  }

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length
    setCurrentIndex(prevIndex)
    handleImageView(prevIndex)
  }

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Carousel 
        index={currentIndex}
        onChange={handleChange}
        navButtonsAlwaysVisible
        next={handleNext}
        prev={handlePrev}
        sx={{ flex: 1 }}
      >
        {signedUrls.map((url, index) => (
          <Box
            key={index}
            component='img'
            src={url || ''}
            alt={`Slide ${index + 1}`}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        ))}
      </Carousel>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        <Button onClick={handlePrev}>Previous</Button>
        <Button onClick={handleNext}>Next</Button>
      </Box>
      <Box sx={{ padding: '10px' }}>
        Images viewed: {viewedImages.size} / {imageUrls.length}
      </Box>
    </Box>
  )
}

export default ImageCarousel
