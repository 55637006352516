import React, { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import url from 'config/server-url'
import Header from 'components/Header'
import Button from '@mui/material/Button'
import FlexBetween from 'components/FlexBetween'
import Typography from '@mui/material/Typography'
import ImageCarousel from 'scenes/resourceViewers/image'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DocumentViewer from 'scenes/resourceViewers/document'
import VideoPlayerWithPlaylist from 'scenes/resourceViewers/video'
import AudioPlayerWithPlaylist from 'scenes/resourceViewers/audio'

import { IconButton } from '@mui/material'
import { Box, useTheme } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const Dashboard = () => {
  const theme = useTheme()

  const roleValue = localStorage.getItem('role')

  const [role, setRole] = useState('')
  const [courseList, setCourseList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [courseDetails, setCourseDetails] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [studentProgress, setStudentProgress] = useState(null)
  const [selectedVideoCategory, setSelectedVideoCategory] = useState(null)
  const [selectedImageCategory, setSelectedImageCategory] = useState(null)
  const [selectedAudioCategory, setSelectedAudioCategory] = useState(null)
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState(null)

  const dataGrid = (columns, rows) => {
    return (
      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: 4,
            backgroundColor: theme.palette.background.normal
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color:
              theme.palette.mode === 'light'
                ? '#FFFFFF'
                : theme.palette.background.main,
            border: 'none',
            borderRadius: 0,
            boxShadow: 5
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background,
            color: theme.palette.secondary[100],
            borderTop: 'none'
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary} !important`
          }
        }}
      >
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          getRowId={row => row._id}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </Box>
    )
  }

  const courseColumns = useMemo(
    () => [
      {
        field: 'courseName',
        headerName: 'Course Name',
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        renderCell: params => (
          <IconButton
            color='primary'
            onClick={() => {
              setSelectedCourse(params.row)
              getCourseStudents(params.row._id)
            }}
          >
            <VisibilityIcon />
          </IconButton>
        )
      }
    ],
    []
  )

  const studentColumns = useMemo(
    () => [
      {
        field: 'studentName',
        headerName: 'Student Name',
        flex: 1,
        renderCell: params => (
          <Button
            variant='text'
            onClick={() => {
              setSelectedStudent(params.row)
              getCourseDetails(params.row.courseId)
              fetchStudentProgress(params.row.user, params.row.courseId)
            }}
          >
            {params.row.studentName}
          </Button>
        )
      },
      {
        field: 'studentContact',
        headerName: 'Contact',
        flex: 1
      },
      {
        field: 'studentAddress',
        headerName: 'Address',
        flex: 1
      },
      {
        field: 'studentStatus',
        headerName: 'Status',
        flex: 1
      }
    ],
    []
  )

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(
        url + 'api/newCourse/getCoursesByStatus',
        {
          adminId,
          courseStatus: 'active'
        }
      )
      setCourseList(response.data)
    } catch (error) {
      console.error('Error getting active courses:', error)
    }
  }

  const getCourseStudents = async courseId => {
    try {
      const getStudents = await axios.post(
        url + 'api/students/getCourseStudents',
        { courseId: courseId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setStudentList(getStudents.data)
    } catch (error) {
      console.error('Error getting students:', error)
    }
  }

  const getCourseDetails = async courseId => {
    try {
      const courseDetailsResponse = await axios.post(
        url + 'api/students/getStudentCourseDetails',
        { courseId: courseId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseDetails(courseDetailsResponse.data)
    } catch (error) {
      console.error('Error getting course details:', error)
    }
  }

  const fetchStudentProgress = async (studentId, courseId) => {
    try {
      const response = await axios.get(
        `${url}api/newCourse/getProgress/${studentId}/${courseId}`
      )
      setStudentProgress(response.data)
    } catch (error) {
      console.error('Error fetching student progress:', error)
    }
  }

  const ChapterProgressBar = ({ chapterName, percentage }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 60,
        mx: 1
      }}
    >
      <Typography variant='body2' fontWeight='bold'>{`${Math.round(
        percentage
      )}%`}</Typography>
      <Box
        sx={{
          height: 100,
          width: 20,
          backgroundColor: '#e0e0e0',
          borderRadius: 5,
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: `${percentage}%`,
            backgroundColor: '#4caf50',
            transition: 'height 0.5s ease-in-out',
            borderRadius: 5
          }}
        />
      </Box>
      <Typography
        variant='caption'
        align='center'
        sx={{ mt: 1, height: 40, overflow: 'hidden' }}
      >
        {chapterName}
      </Typography>
    </Box>
  )

  const OverallProgressCircle = ({ percentage }) => (
    <Box sx={{ position: 'relative', display: 'inline-flex', ml: 2 }}>
      <CircularProgress
        variant='determinate'
        value={100}
        size={120}
        thickness={10}
        sx={{
          color: '#e0e0e0'
        }}
      />
      <CircularProgress
        variant='determinate'
        value={percentage}
        size={120}
        thickness={10}
        sx={{
          color: '#4caf50',
          position: 'absolute',
          left: 0,
          transform: 'rotate(-90deg) scaleY(-1)'
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant='caption' component='div' fontWeight='bold'>
          {`${Math.round(percentage)}%`}
        </Typography>
      </Box>
    </Box>
  )

  const renderResourceCell = params => {
    const resourceCount = params.value
    const circleStyle = {
      width: '18px',
      height: '18px',
      color: 'white',
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: resourceCount > 0 ? 'pointer' : 'default',
      backgroundColor: resourceCount > 0 ? '#01C001' : 'grey'
    }

    const handleCircleClick = () => {
      if (resourceCount > 0) {
        const category = courseDetails.categories.find(
          cat => cat.categoryName === params.field
        )

        if (category) {
          const resourcesDetails = category.resources
            .filter(res => res.chapterId === params.row._id)
            .map(res => ({
              name: res.fileName,
              courseId: courseDetails.course._id,
              chapterId: res.chapterId,
              categoryId: res.categoryId,
              resourceId: res._id
            }))

          if (category.fileType === 'Image') {
            if (resourcesDetails.length > 0) {
              setSelectedImageCategory(resourcesDetails)
            }
          }

          if (category.fileType === 'Video') {
            if (resourcesDetails.length > 0) {
              setSelectedVideoCategory(resourcesDetails)
            }
          }

          if (category.fileType === 'Audio') {
            if (resourcesDetails.length > 0) {
              setSelectedAudioCategory(resourcesDetails)
            }
          }

          if (category.fileType === 'Document') {
            if (resourcesDetails.length > 0) {
              setSelectedDocumentCategory(resourcesDetails)
            }
          }
        }
      }
    }

    return (
      <Box sx={circleStyle} onClick={handleCircleClick}>
        {resourceCount > 0 ? resourceCount : ''}
      </Box>
    )
  }

  const renderCourseDetails = () => {
    if (!courseDetails) return null

    const { course, chapters, categories } = courseDetails

    const rows = chapters.map(chapter => {
      const row = {
        _id: chapter._id,
        chapterName: chapter.chapterName
      }

      categories.forEach(category => {
        const resourcesCount = category.resources.filter(
          res => res.chapterId === chapter._id
        ).length
        row[category.categoryName] = resourcesCount
      })

      return row
    })

    const columns = [
      { field: 'chapterName', headerName: 'Chapter Name', flex: 1 },
      ...categories.map(category => ({
        field: category.categoryName,
        headerName: category.categoryName,
        flex: 1,
        renderCell: renderResourceCell
      }))
    ]

    return (
      <Box
        width='100%'
        sx={{
          marginBottom: '30px'
        }}
      >
        <Box
          sx={{
            padding: '20px',
            display: 'flex',
            borderRadius: '5px',
            marginBottom: '20px',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px'
            }}
          >
            <Typography variant='h4'>Course: {course.courseName}</Typography>
            {role !== 'student' && selectedStudent && (
              <Typography variant='h5'>
                Student: {selectedStudent.studentName}
              </Typography>
            )}
          </Box>
          {studentProgress && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                overflowX: 'auto',
                pb: 2
              }}
            >
              <Box sx={{ display: 'flex', flexGrow: 1, overflowX: 'auto' }}>
                {studentProgress.chapterProgress.map(chapter => (
                  <ChapterProgressBar
                    key={chapter.chapterId}
                    chapterName={chapter.chapterName}
                    percentage={chapter.percentage}
                  />
                ))}
              </Box>
              <Box sx={{ flexShrink: 0, ml: 2 }}>
                <OverallProgressCircle
                  percentage={studentProgress.overallProgress}
                />
              </Box>
            </Box>
          )}
        </Box>
        {dataGrid(columns, rows)}
      </Box>
    )
  }

  const isResourceViewerActive =
    selectedDocumentCategory ||
    selectedVideoCategory ||
    selectedImageCategory ||
    selectedAudioCategory

  useEffect(() => {
    if (roleValue === 'student') {
      setRole('student')
      const studentId = localStorage.getItem('_id')
      const courseId = localStorage.getItem('courseId')

      getCourseDetails(localStorage.getItem('courseId'))
      fetchStudentProgress(studentId, courseId)
    } else {
      getAdminCourses()
    }
  }, [])

  return (
    <Box p='20px'>
      <FlexBetween>
        <Header
          title={role === 'student' ? 'Student Dashboard' : 'Admin Courses'}
        />
        {((role === 'student' && isResourceViewerActive) || selectedCourse) && (
          <Button
            startIcon={<ArrowBackIcon />}
            variant='contained'
            color='primary'
            onClick={() => {
              if (!isResourceViewerActive) {
                setSelectedCourse(null)
                setSelectedStudent(null)
                setCourseDetails(null)
              } else {
                setSelectedDocumentCategory(null)
                setSelectedVideoCategory(null)
                setSelectedImageCategory(null)
                setSelectedAudioCategory(null)
              }
            }}
          >
            {!isResourceViewerActive ? 'Courses' : 'Course Details'}
          </Button>
        )}
      </FlexBetween>

      {role === 'student' ? (
        !isResourceViewerActive ? (
          renderCourseDetails()
        ) : (
          <>
            {selectedImageCategory && (
              <ImageCarousel imageUrls={selectedImageCategory} />
            )}
            {selectedVideoCategory && (
              <VideoPlayerWithPlaylist videos={selectedVideoCategory} />
            )}
            {selectedAudioCategory && (
              <AudioPlayerWithPlaylist audioFiles={selectedAudioCategory} />
            )}
            {selectedDocumentCategory && (
              <DocumentViewer documents={selectedDocumentCategory} />
            )}
          </>
        )
      ) : (
        <>
          {!selectedCourse ? (
            dataGrid(courseColumns, courseList)
          ) : !selectedStudent ? (
            dataGrid(studentColumns, studentList)
          ) : !isResourceViewerActive ? (
            renderCourseDetails()
          ) : (
            <>
              {selectedImageCategory && (
                <ImageCarousel imageUrls={selectedImageCategory} />
              )}
              {selectedVideoCategory && (
                <VideoPlayerWithPlaylist videos={selectedVideoCategory} />
              )}
              {selectedAudioCategory && (
                <AudioPlayerWithPlaylist audioFiles={selectedAudioCategory} />
              )}
              {selectedDocumentCategory && (
                <DocumentViewer documents={selectedDocumentCategory} />
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default Dashboard
