import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'
import url from 'config/server-url'
import ArchiveIcon from '@mui/icons-material/Archive'
import CustomSnackbar from 'components/CustomSnackbar'
import PersonOffIcon from '@mui/icons-material/PersonOff'

import { Box, useTheme, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'



const AllStudents = () => {
  const snackbarRef = useRef(null)

  const theme = useTheme()
  const [allStudents, setAllStudents] = useState([])
  
  const columnFlex = 1
  const columns = [
    {
      field: 'courseName',
      headerName: 'Course',
      flex: columnFlex,
      renderCell: params => params.row.courseName
    },
    { field: 'studentName', headerName: 'Student Name', flex: columnFlex },
    {
      field: 'studentContact',
      headerName: 'Student Contact',
      flex: columnFlex
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: params => (
        <Box>
          <Tooltip title="Archive">
            <IconButton
              color="primary"
              onClick={() => handleEditItemSave(params.row._id, 'archive')}
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Dropout">
            <IconButton
              color="secondary"
              onClick={() => handleEditItemSave(params.row._id, 'dropout')}
            >
              <PersonOffIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ]

  const handleEditItemSave = async (studentId, newStudentStatus) => {
    try {
      const response = await axios.put(url + `api/students/${studentId}`, {
        studentStatus: newStudentStatus
      })

      if (response.status === 200) {
        setAllStudents(prevItems =>
          prevItems.filter(item => item._id !== studentId)
        )
        snackbarRef.current.displaySnackBar(
          'Status updated successfully!',
          'success'
        )
      } else {
        throw new Error('Error editing item')
      }
    } catch (error) {
      console.error('Error editing item:', error)
      snackbarRef.current.displaySnackBar('Error updating status!', 'error')
    }
  }

  useEffect(() => {
    getAdminStudents()
  }, [])

  const getAdminStudents = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(url + 'api/students', {
        adminId: adminId,
        studentStatus: 'active'
      })
      if (response.status === 200) {
        setAllStudents(response.data)
      }
    } catch (error) {
      console.error('Error fetching All Students:', error)
    }
  }

  return (
    <Box p='20px'>
      <h2>Active Students</h2>
      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: 4,
            backgroundColor: theme.palette.background.normal
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color:
              theme.palette.mode === 'light'
                ? '#FFFFFF'
                : theme.palette.background.main,
            border: 'none',
            borderRadius: 0,
            boxShadow: 5
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background,
            color: theme.palette.secondary[100],
            borderTop: 'none'
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary} !important`
          }
        }}
      >
        <DataGrid
          rows={allStudents}
          columns={columns}
          getRowId={row => row._id}
          components={{
            Toolbar: GridToolbar
          }}
          autoHeight
        />
      </Box>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default AllStudents
