import React, { useState, useEffect } from 'react'

import axios from 'axios'
import url from 'config/server-url'
import DeleteIcon from '@mui/icons-material/Delete'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, useTheme, IconButton, Tooltip } from '@mui/material'

const ArchivedCourses = () => {
  const [courseList, setCourseList] = useState([])
  const theme = useTheme()
  const columnFlex = 1
  const columns = [
    { field: 'courseName', headerName: 'Course Name', flex: columnFlex },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: params => (
        <Box>
          <Tooltip title='Unarchive'>
            <IconButton
              color='primary'
              onClick={() => handleEditItemSave(params.row._id, 'active')}
            >
              <UnarchiveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton
              color='error'
              onClick={() => handleEditItemSave(params.row._id, 'deleted')}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ]

  useEffect(() => {
    getArchivedCourses()
  }, [])

  const getArchivedCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(
        url + 'api/newCourse/getCoursesByStatus',
        { adminId, courseStatus: 'archived' }
      )
      if (response.status === 200) {
        setCourseList(response.data)
      } else {
        throw new Error('Error fetching archived courses')
      }
    } catch (error) {
      console.error('Error fetching archived courses:', error)
    }
  }

  const handleEditItemSave = async (courseId, newCourseStatus) => {
    try {
      const response = await axios.put(
        url + `api/newCourse/updateCourseStatus`,
        { courseId, courseStatus: newCourseStatus }
      )
      if (response.status === 200) {
        getArchivedCourses()
      }
    } catch (error) {
      console.error('Error updating course status:', error)
    }
  }

  return (
    <Box p='20px'>
      <h2>Archived Courses</h2>

      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: 4,
            backgroundColor: theme.palette.background.normal
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color:
              theme.palette.mode === 'light'
                ? '#FFFFFF'
                : theme.palette.background.main,
            border: 'none',
            borderRadius: 0,
            boxShadow: 5
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background,
            color: theme.palette.secondary[100],
            borderTop: 'none'
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary} !important`
          }
        }}
      >
        <DataGrid
          autoHeight
          rows={courseList}
          columns={columns}
          getRowId={row => row._id}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </Box>
    </Box>
  )
}

export default ArchivedCourses
