import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import url from '../../config/server-url'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'

const AudioPlayerWithPlaylist = ({ audioFiles }) => {
  const audioRef = useRef(null)
  const [signedUrls, setSignedUrls] = useState({})
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [progressReported, setProgressReported] = useState(false)
  const [listeningTime, setListeningTime] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [role, setRole] = useState(null)

  useEffect(() => {
    setRole(localStorage.getItem('role'))
  }, [])

  const getSignedUrl = async fileName => {
    try {
      const response = await axios.post(`${url}api/s3/get`, {
        fileName
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (!signedUrls[audioFiles[currentTrackIndex].name]) {
        const signedUrl = await getSignedUrl(audioFiles[currentTrackIndex].name)
        setSignedUrls(prev => ({
          ...prev,
          [audioFiles[currentTrackIndex].url]: signedUrl
        }))
      }
    }
    fetchSignedUrl()
  }, [currentTrackIndex, audioFiles])

  const handleTrackChange = index => {
    setCurrentTrackIndex(index)
  }

  const handlePlay = () => setIsPlaying(true)
  const handlePause = () => setIsPlaying(false)

  useEffect(() => {
    let interval
    if (isPlaying) {
      interval = setInterval(() => {
        setListeningTime(prev => prev + 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isPlaying])

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const progress =
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      setCurrentProgress(progress)

      const halfDuration = audioRef.current.duration / 2
      if (listeningTime >= halfDuration && !progressReported) {
        updateProgress(true)
        setProgressReported(true)
      }
    }
  }

  const updateProgress = async () => {
    if (role !== 'student') return

    try {
      const currentAudio = audioFiles[currentTrackIndex]
      await axios.post(`${url}api/newCourse/updateProgress`, {
        studentId: localStorage.getItem('_id'),
        courseId: currentAudio.courseId,
        chapterId: currentAudio.chapterId,
        categoryId: currentAudio.categoryId,
        resourceId: currentAudio.resourceId
      })
    } catch (error) {
      console.error('Error updating progress:', error)
    }
  }

  useEffect(() => {
    setProgressReported(false)
    setListeningTime(0)
    setIsPlaying(false)
  }, [currentTrackIndex])

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {audioFiles[currentTrackIndex].name}
        </Typography>
        <audio
          ref={audioRef}
          controls
          src={signedUrls[audioFiles[currentTrackIndex].url] || ''}
          style={{ width: '100%' }}
          onTimeUpdate={handleTimeUpdate}
          onPlay={handlePlay}
          onPause={handlePause}
        >
          Your browser does not support the audio element.
        </audio>
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {audioFiles.map((res, index) => (
            <ListItem
              button
              selected={index === currentTrackIndex}
              onClick={() => handleTrackChange(index)}
              key={index}
            >
              <ListItemAvatar>
                <Avatar>
                  <MusicNoteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default AudioPlayerWithPlaylist
