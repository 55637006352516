import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import url from 'config/server-url.jsx'
import { Box, useTheme, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteIcon from '@mui/icons-material/Delete'


const ActiveCourses = () => {
  const [courseList, setCourseList] = useState([])
  const theme = useTheme()
  const navigate = useNavigate()

  const columnFlex = 1
  const columns = [
    {
      field: 'courseName',
      headerName: 'Course Name',
      flex: columnFlex,
      renderCell: params => params.row.courseName
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: params => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() => handleEditItem(params.row._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Archive">
            <IconButton
              color="success"
              onClick={() => handleEditItemSave(params.row._id, 'archived')}
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleEditItemSave(params.row._id, 'deleted')}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ]

  const handleEditItem = courseId => {
    navigate(`/update course/${courseId}`)
  }

  const handleEditItemSave = async (courseId, newCourseStatus) => {
    try {
      const response = await axios.put(
        url + `api/newCourse/updateCourseStatus`,
        { courseId, courseStatus: newCourseStatus }
      )

      if (response.status === 200) {
        getActiveCourses()
      }
    } catch (error) {
      console.error('Error editing item:', error)
    }
  }

  const getActiveCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(
        url + 'api/newCourse/getCoursesByStatus',
        {
          adminId,
          courseStatus: 'active'
        }
      )
      setCourseList(response.data)
    } catch (error) {
      console.error('Error getting active courses:', error)
    }
  }

  useEffect(() => {
    getActiveCourses()
  }, [])

  return (
    <Box p='20px'>
      <h2>Active Courses</h2>
      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: 4,
            backgroundColor: theme.palette.background.normal
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color:
              theme.palette.mode === 'light'
                ? '#FFFFFF'
                : theme.palette.background.main,
            border: 'none',
            borderRadius: 0,
            boxShadow: 5
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background,
            color: theme.palette.secondary[100],
            borderTop: 'none'
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary} !important`
          }
        }}
      >
        <DataGrid
          autoHeight
          rows={courseList}
          columns={columns}
          getRowId={row => row._id}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </Box>
    </Box>
  )
}

export default ActiveCourses
