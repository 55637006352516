import React, { useState, useRef } from 'react'
import { Box, Button, TextField } from '@mui/material'
import axios from 'axios'
import url from 'config/server-url'
import Grid from '@mui/material/Grid'
import CustomSnackbar from 'components/CustomSnackbar.jsx'
import { mainBoxStyle } from 'styles.js'

const AddCourse = () => {
  const snackbarRef = useRef()

  const [newCourse, setNewCourse] = useState({
    adminId: '',
    courseName: ''
  })

  const handleNewCourseInputChange = e => {
    const { name, value } = e.target
    setNewCourse(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleNewCourseSave = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const formData = new FormData()

      formData.append('adminId', adminId)
      formData.append('courseName', newCourse.courseName)

      const newCourseApiResponse = await axios.post(
        url + 'api/newCourse',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (newCourseApiResponse.status === 200) {
        setNewCourse({
          courseName: ''
        })
        snackbarRef.current.displaySnackBar('Course Added', 'success')
      }
    } catch (error) {
      console.error('Error creating AddCourse:', error)
    }
  }

  return (
    <Box sx={mainBoxStyle}>
      <h2>Add Course</h2>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <TextField
            size='small'
            type='text'
            name='courseName'
            value={newCourse.courseName}
            onChange={handleNewCourseInputChange}
            label='Enter Course Name'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNewCourseSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default AddCourse
