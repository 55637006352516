import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'axios'
import url from 'config/server-url'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import logoImage from '../../assets/logo.png'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CssBaseline from '@mui/material/CssBaseline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const defaultTheme = createTheme()

const Login = ({ handleAuthentication }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [role, setRole] = useState('')

  const { type } = useParams()

  useEffect(() => {
    if (type === 'student') {
      setRole('Student')
    } else if (type === 'staff') {
      setRole('Staff')
    } else {
      setRole('EduSupplements')
    }
  }, [type])

  const validateEmail = email => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  const handleLogin = async () => {
    setEmailError('')
    setPasswordError('')
    setError('')

    if (!email) {
      setEmailError('Email is required')
      return
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    }
    if (!password) {
      setPasswordError('Password is required')
      return
    }
    // if (password.length < 6) {
    //   setPasswordError('Password must be at least 6 characters long')
    //   return
    // }

    try {
      const response = await axios.post(url + 'api/login', {
        email,
        password
      })

      localStorage.setItem('email', response.data.user.email)
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('role', response.data.user.role)
      localStorage.setItem('_id', response.data.user._id)
      localStorage.setItem('admin_id', response.data.userData._id)

      const userRole = response.data.user.role

      if (userRole === 'admin') {
        localStorage.setItem('admin_name', response.data.userData.adminname)
      } else if (userRole === 'student') {
        localStorage.setItem('courseId', response.data.userData.courseId)
        localStorage.setItem('student_name', response.data.userData.studentName)
      } else if (userRole === 'superAdmin') {
        localStorage.setItem(
          'superadmin_name',
          response.data.userData.superadminname
        )
      }

      handleAuthentication(true)
    } catch (error) {
      setError('Login failed. Please check your email and password.')
      console.error(error)
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          bgcolor: 'background.default'
        }}
      >
        <Container component='main' maxWidth='xs' disableGutters>
          <CssBaseline />
          <Card elevation={5}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Avatar
                  src={logoImage}
                  sx={{ m: 1, bgcolor: 'secondary.main' }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  variant='h5'
                  component='h1'
                  style={{ fontWeight: 'bold' }}
                >
                  {role} Login
                </Typography>

                <Box sx={{ mt: 1, width: '100%' }}>
                  {error && (
                    <Typography
                      variant='body2'
                      color='error'
                      align='center'
                      style={{ marginBottom: '15px' }}
                    >
                      {error}
                    </Typography>
                  )}
                  <TextField
                    autoFocus
                    size='small'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    value={email}
                    error={!!emailError}
                    helperText={emailError}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <TextField
                    size='small'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <Button
                    fullWidth
                    type='submit'
                    color='primary'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                  {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link href='#' variant='body2'>
                      Forgot password?
                    </Link>
                    <Link href='#' variant='body2'>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Box> */}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Login
