import { useMemo, useState, useEffect } from 'react'

import Layout from 'scenes/layout'
import Dashboard from 'scenes/dashboard'

import { themeSettings } from 'theme'
import { createTheme } from '@mui/material/styles'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

// Screens Imports
import Admin from 'scenes/superadmin/Admin'
import SuperAdmin from 'scenes/superadmin/SuperAdmin'

// Login Imports
import Login from 'scenes/login/Login'
import SplashScreen from 'scenes/login/SplashScreen'

// Student Imports
import InviteStudent from 'scenes/admin/InviteStudent'
import ActiveStudents from 'scenes/students/ActiveStudents'
import ArchievedStudents from 'scenes/students/ArchivedStudents'
import DropOutStudents from 'scenes/students/DropoutStudents'

// Course Pages Imports
import NewCourse from 'scenes/courses/NewCourse'
import AddCourse from 'scenes/courses/AddCourse'
import AddChapter from 'scenes/courses/AddChapter'
import AddCategory from 'scenes/courses/AddCategory'
import AddResource from 'scenes/courses/AddResource'
import ActiveCourses from 'scenes/courses/ActiveCourses'
import UpdateCourse from 'scenes/courses/UpdateCourse'
import ArchivedCourses from 'scenes/courses/ArchivedCourses'

function App () {
  const theme = useMemo(() => createTheme(themeSettings()))
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  )
  const [showSplash, setShowSplash] = useState(!isAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      setShowSplash(false)
    }
  }, [isAuthenticated])

  const handleAuthentication = status => {
    setIsAuthenticated(status)
    localStorage.setItem('isAuthenticated', status.toString())
  }

  const setSplashScreen = status => {
    setShowSplash(status)
  }

  const authenticatedRoutes = (
    <Route element={<Layout />}>
      <Route path='/' element={<Navigate to='/dashboard' replace />} />

      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/Register SuperAdmin' element={<SuperAdmin />} />
      <Route path='/Register Admin' element={<Admin />} />

      {/* Students Routes */}
      <Route path='/Invite Student' element={<InviteStudent />} />
      <Route path='/Active Students' element={<ActiveStudents />} />
      <Route path='/Archived Students' element={<ArchievedStudents />} />
      <Route path='/DropOut Students' element={<DropOutStudents />} />

      {/* Course Pages Routes */}
      <Route path='/New Course' element={<NewCourse />} />
      <Route path='/Update Course/:courseId' element={<UpdateCourse />} />
      <Route path='/Add Course' element={<AddCourse />} />
      <Route path='/Add Chapter' element={<AddChapter />} />
      <Route path='/Add Category' element={<AddCategory />} />
      <Route path='/Add Resource' element={<AddResource />} />
      <Route path='/Active Courses' element={<ActiveCourses />} />
      <Route path='/Archived Courses' element={<ArchivedCourses />} />
    </Route>
  )

  return (
    <div className='app'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {!isAuthenticated && showSplash ? (
              <Route
                path='*'
                element={<SplashScreen setSplashScreen={setSplashScreen} />}
              />
            ) : !isAuthenticated ? (
              <Route
                path='/login/:type'
                element={<Login handleAuthentication={handleAuthentication} />}
              />
            ) : (
              <>
                <Route
                  path='*'
                  element={<Navigate to='/dashboard' replace />}
                />
                {authenticatedRoutes}
              </>
            )}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
