import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Box, Button, TextField, Select, MenuItem, Grid } from '@mui/material'
import url from '../../config/server-url.jsx'
import CustomSnackbar from 'components/CustomSnackbar.jsx'
import { mainBoxStyle } from 'styles.js'

const AddChapter = () => {
  const snackbarRef = useRef()
  const [chapter, setChapter] = useState({
    courseId: '',
    chapterName: ''
  })
  const [courseList, setCourseList] = useState([])

  useEffect(() => {
    getAdminCourses()
  }, [])

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  const handleNewChapterInputChange = e => {
    const { name, value } = e.target
    setChapter(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleNewChapterSave = async () => {
    try {
      const formData = new FormData()

      formData.append('courseId', chapter.courseId)
      formData.append('chapterName', chapter.chapterName)

      const newCourseApiResponse = await axios.post(
        url + 'api/newCourse',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (newCourseApiResponse.status === 200) {
        snackbarRef.current.displaySnackBar('Chapter Added', 'success')
        setChapter({
          courseId: '',
          chapterName: ''
        })
      }
    } catch (error) {
      console.error('Error creating AddChapter:', error)
    }
  }

  return (
    <Box sx={mainBoxStyle}>
      <h2>Add Chapter</h2>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Select
            fullWidth
            size='small'
            name='courseId'
            value={chapter.courseId}
            onChange={handleNewChapterInputChange}
            inputProps={{ 'aria-label': 'Select Course' }}
            displayEmpty
          >
            <MenuItem value='' disabled>
              Select Course
            </MenuItem>
            {courseList.map(course => (
              <MenuItem key={course._id} value={course._id}>
                {course.courseName}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size='small'
            type='text'
            name='chapterName'
            value={chapter.chapterName}
            onChange={handleNewChapterInputChange}
            label='Enter Chapter Name'
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNewChapterSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default AddChapter
