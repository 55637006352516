import React, { useState, useEffect } from 'react'

import axios from 'axios'
import url from 'config/server-url'

import { Box, Typography } from '@mui/material'

const DocumentViewer = ({ documents }) => {
  const [signedUrls, setSignedUrls] = useState({})
  const [role, setRole] = useState(null)

  useEffect(() => {
    setRole(localStorage.getItem('role'))
  }, [])

  const getSignedUrl = async fileName => {
    try {
      const response = await axios.post(`${url}api/s3/get`, {
        fileName
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  const handleDocumentClick = async document => {
    let urlToOpen = signedUrls[document.url]

    if (!urlToOpen) {
      const signedUrl = await getSignedUrl(document.name)
      if (signedUrl) {
        setSignedUrls(prev => ({ ...prev, [document.url]: signedUrl }))
        urlToOpen = signedUrl
      } else {
        console.error('Failed to get signed URL')
        return
      }
    }

    window.open(urlToOpen, '_blank')

    if (role === 'student') {
      try {
        await axios.post(`${url}api/newCourse/updateProgress`, {
          studentId: localStorage.getItem('_id'),
          courseId: document.courseId,
          chapterId: document.chapterId,
          categoryId: document.categoryId,
          resourceId: document.resourceId
        })
      } catch (error) {
        console.error('Error updating progress:', error)
      }
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      sx={{ marginTop: '30px' }}
    >
      {documents.map((res, index) => (
        <Box
          key={index}
          marginBottom='20px'
          width='100%'
          textAlign='left'
          onClick={() => handleDocumentClick(res)}
          style={{ cursor: 'pointer' }}
        >
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'black', textDecoration: 'none', marginRight: '10px' }}
          >
            {index + 1}.
          </Typography>
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'blue', textDecoration: 'none' }}
          >
            {res.name}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default DocumentViewer
